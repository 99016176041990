<footer class="page-footer font-small pt-4">
  <div class="row">
    <nav class="navbar navbar-dark bg-secondary fixed-bottom">
      <div class="navbar-brand">
        <div class="footer-copyright py-3 text-center small align-self-end">©2021 Copyright &nbsp;
          <a href="https://itconsulting-will.de" target="_blank">IT-Consulting Will</a>
        </div>
      </div>
      <div class="navbar-expand ml-auto">
        <div class="navbar-nav nav-justified">
          <a class="nav-item nav-link" routerLink="impressum">Impressum</a>
          <a class="nav-item nav-link" routerLink="dataprotect">Datenschutz</a>
        </div>
      </div>
    </nav>
  </div>  
</footer>
